import React from "react";

import { Helmet } from "react-helmet";

import DynamicComponentLoader from "../components/DynamicComponent";

export default function Default({ isLoading, pageTitle, pageId, ...props }) {
  const Content = props?.data?.PageContent;

  // TODO use something like this to pass only relevant data to component
  function findPageContentByComponent(object, component) {
    if (object.data && Array.isArray(object.data.PageContent)) {
      return object.data.PageContent.find(page => page.__component === component);
    }
    return null;
  }
  return (
    <>
      <Helmet>
        <title itemProp="name" lang="en">
          {pageTitle}
        </title>
      </Helmet>

      {Content &&
        Content?.map((block, index) => {
          const { data, ...restOfProps } = props;
          const componentData = { ...block };

          return (
            <DynamicComponentLoader
              key={`${block.Copy}${index}`}
              theme={props?.theme}
              {...componentData}
              {...restOfProps}
            />
          );
        })}
      {props?.children}
    </>
  );
}
