import React from "react";
import { Container, Grid, Typography, Button } from "@mui/material";

import SimpleResponsivePicture from "./SimpleResponsivePicture";
import { parseWithLinks } from "../Functions";

export default function RentalProperties(props) {
  return (
    <Container disableGutters maxWidth="xl" sx={{ ...props?.options?.wrapperStyle }} align="center">
      <Typography variant="h2" fullWidth align="center">
        {props?.Title}
      </Typography>
      <Grid container spacing={2} maxWidth="lg">
        {props?.propertiesData?.map((item, index) => {
          return (
            <Grid item container xs={12} sm={6} md={6} direction={"column"}>
              <SimpleResponsivePicture imageObj={item.attributes.Image.data.attributes} />
              <Typography
                variant="h4"
                sx={{ marginTop: "5rem", width: "100%" }}
                align={"left"}
                paragraph
              >
                {item.attributes.Title}
              </Typography>
              {parseWithLinks(item.attributes.Description, { alignText: "left" })}
              {/* {item.attributes.showhomes.data && item.attributes.showhomes.data.length > 0 && (
                <Button variant="contained" sx={{ marginTop: "1rem" }} href="/showhome">
                  See Showhomes & Floorplans >
                </Button>
              )} */}
              {item.attributes.Website && (
                <Button
                  variant="contained"
                  sx={{ marginTop: "1rem" }}
                  href={item.attributes.Website}
                  target="Clearwater"
                >
                  Visit {item.attributes.Title} >
                </Button>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
