import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate, NavLink } from "react-router-dom";
import { useTheme, Fab, useMediaQuery, Container, Hidden, IconButton, Alert } from "@mui/material";
import { Block } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { motion, AnimatePresence } from "framer-motion";

import ScrollTop from "./components/ScrollTop";
import { Event, initGA } from "./components/Tracking";
import styled from "@emotion/styled";

import ArrowUpward from "@mui/icons-material/ArrowUpward";

import "./App.css";

import {
  MainNavigation,
  MobileMenu,
  Palette,
  Home,
  News,
  NewsDetail,
  Default,
  NoMatch,
  Footer,
  Showhome,
} from "./components/index.js";

import { scrollToTop, getStrapiContent, convertColor } from "./Functions";

const pageAnimations = {
  hidden: {
    opacity: 0.05,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.125,
    },
  },

  exit: { opacity: 0.0, transition: { duration: 0.125 } },
};

function App(props) {
  useEffect(() => initGA("G-5HYG31PEMB"), []);
  const theme = useTheme();
  const locationObject = useLocation();
  const [message, setMessage] = useState("");
  const showTextNav = useMediaQuery(theme.breakpoints.up("lg"));
  const [IsLoadingData, setIsLoadingData] = useState(true);
  const [Error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isTouch, setIsTouch] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isXsmall = useMediaQuery(theme.breakpoints.only("xs"));
  const [newsId, setNewsId] = useState();
  const [newsDetail, setNewsDetail] = useState();
  const [HomeData, setHomeData] = useState([{}]);
  const [NewsPageData, setNewsPageData] = useState();
  const [LivingHereData, setLivingHereData] = useState([{}]);
  const [BuilderData, setBuilderData] = useState();
  const [ClubhouseData, setClubhouseData] = useState();
  const [GalleryData, setGalleryData] = useState();
  const [FaqData, setFaqData] = useState();
  const [VisitUsData, setVisitUsData] = useState();
  const [ShowHomeData, setShowHomeData] = useState();
  const [PropertiesData, setPropertiesData] = useState();
  const [RetailLeasingData, setRetailLeasingData] = useState();
  const [ForLeaseData, setForLeaseData] = useState();
  //TODO This is the signature to use for setting the hero in this context. Need to
  // setHero({ heroTitle: res.data.Hero.HeroTitle, heroImage: res.data.Hero.HeroImage[0] });

  const handleOnTouchStart = event => {
    setIsTouch(true);
  };

  const contentParameters = [
    {
      apiPath: "/api/properties",
      setter: setPropertiesData,
      data: PropertiesData,
      localStorageVariableName: "cw-properties",

      // component: Layout,
    },
    {
      apiPath: "/api/showhomes",
      setter: setShowHomeData,
      data: ShowHomeData,
      localStorageVariableName: "cw-showhome",

      // component: Layout,
    },
    {
      apiPath: "/api/home/",
      setter: setHomeData,
      data: HomeData,
      localStorageVariableName: "cw-home",
      path: "/",
      pageTitle: "Welcome",
      pageId: "home",
      name: "Home",
      altText: "Clearwater Home",
      // component: Layout,
    },
    {
      apiPath: "/api/living-here/",
      setter: setLivingHereData,
      data: LivingHereData,
      localStorageVariableName: "cw-lh",
      path: "/living-here",
      link: "/living-here",
      pageTitle: "Living Here",
      pageId: "living-here",
      name: "Living Here",
      altText: "Living Here",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/builder/",
      setter: setBuilderData,
      data: BuilderData,
      localStorageVariableName: "cw-builder",
      path: "/builders",
      link: "/builders",
      pageTitle: "Builders",
      pageId: "builders",
      name: "Builders",
      altText: "Builders",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/retail-leasing/",
      setter: setRetailLeasingData,
      data: RetailLeasingData,
      localStorageVariableName: "cw-retail",
      path: "/retail",
      link: "/retail",
      pageTitle: "Retail",
      pageId: "retail",
      name: "Retail",
      altText: "Retail",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/for-lease",
      setter: setForLeaseData,
      data: ForLeaseData,
      localStorageVariableName: "cw-forlease",
      path: "/for-lease",
      link: "/for-lease",
      pageTitle: "Homes For Lease",
      pageId: "for-lease",
      name: "Homes For Lease",
      altText: "for-lease",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/community-clubhouse/",
      setter: setClubhouseData,
      data: ClubhouseData,
      localStorageVariableName: "cw-clubhouse",
      path: "/community-clubhouse",
      link: "/community-clubhouse",
      pageTitle: "Community Clubhouse",
      pageId: "community-clubhouse",
      name: "Community Clubhouse",
      altText: "Community Clubhouse",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/gallery",
      setter: setGalleryData,
      data: GalleryData,
      localStorageVariableName: "cw-gallery",
      path: "/gallery",
      link: "/gallery",
      pageTitle: "Gallery",
      pageId: "gallery",
      name: "Gallery",
      altText: "Gallery",
      navComponent: NavLink,
    },
    {
      apiPath: "/api/news-page",
      setter: setNewsPageData,
      data: NewsPageData,
      localStorageVariableName: "cw-news",
      path: "/news",
      link: "/news",
      pageTitle: "News",
      pageId: "news",
      name: "News",
      altText: "News",
      navComponent: NavLink,
    },
    {
      apiPath: "/api/faq",
      setter: setFaqData,
      data: FaqData,
      localStorageVariableName: "cw-faq",
      path: "/faq",
      link: "/faq",
      pageTitle: "FAQ",
      pageId: "faq",
      name: "FAQ",
      altText: "FAQ ",
      navComponent: NavLink,
    },
    {
      apiPath: "/api/visit-us",
      setter: setVisitUsData,
      data: VisitUsData,
      localStorageVariableName: "cw-visit",
      path: "/visit-us",
      link: "/visit-us",
      pageTitle: "Visit Us",
      pageId: "visit-us",
      name: "Visit Us",
      altText: "Visit Us",
      navComponent: NavLink,
    },
    // {
    //   apiPath: "/gallery/",
    //   setter: setBuilderData,
    //   data: BuilderData,
    //   localStorageVariableName: "cw-gallery",
    //   path: "/gallery",
    //   link: "/gallery",
    //   pageTitle: "Clearwater Park Gallery",
    //   pageId: "gallery",
    //   name: "Gallery",
    //   altText: "Clearwater Park Gallery",
    //   navComponent: NavLink,
    //   // component: Layout,
    // },
  ];

  useEffect(() => {
    contentParameters.forEach(
      async ({ apiPath, setter, localStorageVariableName }, index, array) => {
        if (apiPath) {
          try {
            await getStrapiContent(apiPath, setter, localStorageVariableName).then(res => {
              if (apiPath === "/api/home/" && res?.createdAt) {
                setIsLoadingData(false);
                return true;
              }
            });
          } catch (error) {
            setError(error);
            return false;
          }
        }
      }
    );
  }, []);

  useEffect(() => {
    const nd = NewsPageData?.PageContent[1].NewsItems.filter(element => {
      if (element.id === Number(newsId)) {
        return element;
      }
    })[0];
    setNewsDetail(nd);
  }, [NewsPageData]);

  const handleNewsClick = e => {
    setNewsId(Number(e?.currentTarget?.dataset?.id));

    const nd = NewsPageData?.PageContent[1].NewsItems.filter(element => {
      if (element.id === Number(e.currentTarget?.dataset?.id)) {
        return element;
      }
    })[0];

    setNewsDetail(nd);
    scrollToTop();
    return true;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  return (
    <>
      <Container className="App" maxWidth="xl" disableGutters onTouchStart={handleOnTouchStart}>
        {showTextNav && (
          <MainNavigation
            scrollToTop={scrollToTop}
            setIsModalOpen={setIsModalOpen}
            theme={theme}
            hasBuilderData={BuilderData?.createdAt ? true : false}
            contentParameters={contentParameters}
          />
        )}
        {!showTextNav && (
          <MobileMenu
            scrollToTop={scrollToTop}
            contentParameters={contentParameters}
            theme={theme}
            hasBuilderData={BuilderData?.createdAt ? true : false}
          />
        )}
        <ContentContainer disableGutters maxWidth="xl">
          <Routes>
            {/* <Route path="/palette" element={<Palette />} /> */}
            {/* <Route path="/" index exact element={<Navigate replace to="home" />} /> */}

            <Route
              index
              exact
              path="/"
              element={
                <React.Fragment>
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={locationObject.pathname}
                      exit="exit"
                      initial="hidden"
                      animate="visible"
                      variants={pageAnimations}
                    >
                      {showAlert && (
                        <Alert
                          severity="error"
                          style={{
                            display: Block,
                            maxWidth: "fit-content",
                            margin: "0 auto",
                          }}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setShowAlert(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {message}
                        </Alert>
                      )}

                      <Home
                        convertColor={convertColor}
                        theme={theme}
                        data={HomeData}
                        isLoading={!HomeData?.createdAt}
                        isSmall={isSmall}
                        isTouch={isTouch}
                        isXsmall={isXsmall}
                        scrollToTop={scrollToTop}
                      />
                    </motion.div>
                  </AnimatePresence>
                </React.Fragment>
              }
            />
            <Route
              path="/showhome"
              element={
                <Showhome
                  theme={theme}
                  // data={VisitUsData}
                  // showHomeData={ShowHomeData}
                  pageTitle="Showhome"
                  pageId="showhome"
                  // isLoading={!VisitUsData?.createdAt}
                />
              }
            />
            <Route
              path="/visit-us"
              element={
                <Default
                  theme={theme}
                  data={VisitUsData}
                  showHomeData={ShowHomeData}
                  pageTitle="Visit Us"
                  pageId="visit-us"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!VisitUsData?.createdAt}
                />
              }
            />
            <Route
              path="/for-lease"
              element={
                <Default
                  theme={theme}
                  data={ForLeaseData}
                  propertiesData={PropertiesData}
                  pageTitle="Homes For Lease"
                  pageId="for-lease"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!ForLeaseData?.createdAt}
                />
              }
            />
            <Route
              path="/retail"
              element={
                <Default
                  theme={theme}
                  data={RetailLeasingData}
                  pageTitle="Retail"
                  pageId="retail"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!RetailLeasingData?.createdAt}
                />
              }
            />
            {/* <Route
              path="/maps"
              element={
                <Maps
                  theme={theme}
                  pageTitle="Maps"
                  pageId="maps"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                />
              }
            /> */}
            <Route
              path="/faq"
              element={
                <Default
                  theme={theme}
                  data={FaqData}
                  pageTitle="FAQ"
                  pageId="faq"
                  isLoading={!FaqData?.createdAt}
                />
              }
            />
            <Route
              path="/gallery"
              element={
                <Default
                  theme={theme}
                  data={GalleryData}
                  pageTitle="Gallery"
                  pageId="gallery"
                  isLoading={!GalleryData?.createdAt}
                />
              }
            />
            <Route
              path="/news"
              element={
                <News
                  index={true}
                  theme={theme}
                  pageTitle="News"
                  pageId="news"
                  isLoading={!NewsPageData?.createdAt}
                />
              }
            >
              <Route
                path="/news"
                element={
                  <Default
                    theme={theme}
                    data={NewsPageData}
                    pageTitle="News"
                    pageId="news"
                    isSmall={isSmall}
                    isXsmall={isXsmall}
                    isTouch={isTouch}
                    handleNewsClick={handleNewsClick}
                    isLoading={!NewsPageData?.createdAt}
                  />
                }
              />
              <Route
                path="/news/:id"
                element={
                  <NewsDetail setNewsId={setNewsId} newsId={newsId} newsDetail={newsDetail} />
                }
              />
            </Route>
            <Route
              path="/living-here"
              element={
                <Default
                  theme={theme}
                  data={LivingHereData}
                  pageTitle="Living Here"
                  pageId="living-here"
                  isLoading={!LivingHereData?.createdAt}
                  isTouch={isTouch}
                  isXsmall={isXsmall}
                  isSmall={isSmall}
                />
              }
            />

            <Route
              path="/builders"
              element={
                <Default
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  theme={theme}
                  data={BuilderData}
                  propertiesData={PropertiesData}
                  pageTitle="Builders"
                  pageId="builders"
                  isLoading={!BuilderData?.createdAt}
                />
              }
            />

            <Route
              path="/community-clubhouse"
              element={
                <Default
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  theme={theme}
                  data={ClubhouseData}
                  pageTitle="Community Clubhouse"
                  pageId="community-clubhouse"
                  isLoading={!ClubhouseData?.createdAt}
                />
              }
            />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </ContentContainer>
        {!isXsmall && <Footer isSmall={isSmall} isXsmall={isXsmall} />}
        {isXsmall && <Footer isSmall={isSmall} isXsmall={isXsmall} />}
      </Container>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <ArrowUpward />
        </Fab>
      </ScrollTop>
    </>
  );
}

const ContentContainer = styled(Container)`
  margin-top: 55px;
  ${props => props.theme.breakpoints.up("sm")} {
    margin-top: 66px;
  }
  ${props => props.theme.breakpoints.up("md")} {
    margin-top: 66px;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    margin-top: 110px;
  }
`;

export default App;
