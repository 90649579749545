import React from "react";
import { Grid, Container, Typography } from "@mui/material";

import SimpleResponsivePicture from "./SimpleResponsivePicture";
import StrapiGrid from "./StrapiGrid";
import StrapiGridItemDanger from "./StrapiGridItemDanger";

import { addClassToLinksInParagraphs } from "../Functions";

export default function ImageLeft(props) {
  // TODO
  const imageBlockStyle = props?.options?.imageBlockStyle || {};
  const textBlockStyle = props?.options?.textBlockStyle || {};
  const { justifyContent, alignContent, columnGap, wrap, ...layoutOptions } =
    props?.options?.layout || false;
  const { sx } = textBlockStyle?.innerStyles || {};

  const layout = props?.options?.layout || {};

  let result = props.Copy;
  if (layout?.useButtonForLink) {
    result = addClassToLinksInParagraphs(result, "button", "_blank");
  }

  return (
    <Container
      maxWidth="xl"
      align="center"
      disableGutters
      sx={{ ...props?.options?.wrapperStyle?.sx }}
    >
      {props?.options?.title && (
        <Typography variant="h2" color="primary.red" align="center" width={"100%"}>
          {props?.options?.title}
        </Typography>
      )}
      <StrapiGrid options={props?.options?.layout}>
        <Grid
          xs={12}
          sm={props?.options?.layout?.imageSize.sm || 5}
          md={props?.options?.layout?.imageSize.md || 5}
          lg={props?.options?.layout?.imageSize.lg || 5}
          xl={props?.options?.layout?.imageSize.xl || 5}
          item
          container
          alignItems="center"
          sx={{ ...imageBlockStyle }}
        >
          <SimpleResponsivePicture
            sx={{ ...imageBlockStyle.imageStyle }}
            imageObj={props?.Image?.data?.attributes}
          />
        </Grid>

        <StrapiGridItemDanger
          textBlockStyle={textBlockStyle}
          //innerStyles is deconstructed in StrapiGridItemDanger
          sx={sx}
          Copy={result}
        />
      </StrapiGrid>
    </Container>
  );
}
