import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import { AppBar, Toolbar, IconButton, Drawer } from "@mui/material";

// import { HashLink } from "react-router-hash-link";
import { Event } from "./Tracking";
import { Menu as MenuIcon, Close } from "@mui/icons-material";

import { useViewportScroll } from "framer-motion";
import NavItem from "./NavItem";
import LogoSVG from "../assets/navLogo.svg";
import LogoReversed from "../assets/navLogo.svg";
import SocialIcons from "./SocialIcons";

export default function MobileMenu({ contentParameters, ...props }) {
  const [progress, setProgress] = useState(0);
  const { scrollYProgress } = useViewportScroll();

  useEffect(() => {
    scrollYProgress.onChange(v => {
      setProgress(v * 100);
    });
    return () => true;
  }, [scrollYProgress]);

  const [open, setOpen] = React.useState(false);
  const handleToggle = event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(!open);
  };

  const createEvent = event => {
    Event("Nav", "click", event.currentTarget.text);
  };

  const handleOnClick = event => {
    createEvent(event);
    props.scrollToTop();

    return true;
  };

  return (
    <AppBar position="fixed">
      <StyledToolbar>
        <NavItem href="/" handleOnClick={handleOnClick}>
          <Logo showmobile={true} alt="Clearwater Logo" role="presentation" src={LogoSVG} />
        </NavItem>

        <StyledIconButton
          edge="start"
          color="primary.red"
          aria-label="open drawer"
          // open={handleToggle}
          onClick={handleToggle}
        >
          <StyledMenuIconButton />
        </StyledIconButton>
      </StyledToolbar>
      {/* TODO close drawer when window is resized */}

      <Drawer
        open={open}
        // containerStyle={styles.navBar}
        PaperProps={{ component: StyledPaper }}
        onClick={handleToggle}
        onKeyDown={handleToggle}
        role="presentation"
        anchor="right"
        id="Drawer"
      >
        <Close style={{ alignSelf: "flex-end" }} />

        <DrawerNavContainer id="drawer-nav-container">
          <NavItem
            href="/"
            onClick={props.handleNavClick}
            aria-controls="navigation"
            style={{ marginBottom: "2rem" }}
          >
            <DrawerLogo src={LogoReversed} />
          </NavItem>
          {contentParameters?.map((item, index) => {
            if (item.name === "Builders" && !props?.hasBuilderData) {
              return;
            }
            return (
              item.hasOwnProperty("name") &&
              item.path !== "/" && (
                <NavItem
                  key={item.name}
                  href={item.link}
                  alt={item.alt}
                  aria-controls="navigation"
                  handleOnClick={handleOnClick}
                >
                  {item.name}
                </NavItem>
              )
            );
          })}
          <SocialIcons
            instagram="https://instagram.com"
            facebook="https://facebook.com"
            iconcolor={props.theme.palette.primary.main}
            style={{ justifyContent: "flex-start" }}
          />
        </DrawerNavContainer>
      </Drawer>
    </AppBar>
  );
}

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 1.8rem;
  background-color: transparent;
`;

const StyledMenuIconButton = styled(MenuIcon)`
  background-color: transparent;
  width: 1.3em;
  height: 1.3em;
  color: ${props => props.theme.palette.primary.dark};
`;

const StyledPaper = styled("div")`
  overflow: hidden;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 26%),
    center 100% / 113% auto no-repeat url();
  padding-top: 2em;
  padding-right: 2em;
  width: 300px;
  @media (min-width: 480px) {
    width: 300px;
  }
`;
const DrawerNavContainer = styled("nav")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  padding: 0 0 3em 3em;
  flex: 1;
  @media (min-width: 480px) {
    width: 100%;
  }

  a.active {
    background-color: transparent;
    text-decoration: none;
    &:before {
      content: "";
    }
  }

  .MuiButton-contained {
    margin-top: 1rem;
    background-color: ${props => props.theme.palette.primary.dark};

    color: ${props => props.theme.palette.common.white};
    &:hover {
      background-color: ${props => props.theme.palette.secondary.dark};
    }
  }

  .MuiButton-label {
    font-size: 1rem;
    font-style: normal;
    color: ${props => props.theme.palette.common.white};
  }

  a {
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    margin: 0.5em;
    color: ${props => props.theme.palette.primary.main};
    font-family: "Poppins", sans-serif;
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }

    &.active {
      color: ${props => props.theme.palette.primary.red};
      font-family: "Poppins", sans-serif;
      font-weight: 800;
      &:before {
        display: inline-block;
        position: absolute;
        left: 35px;
        color: ${props => props.theme.palette.primary.red};
        content: ">";
      }
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.common.white};
  width: 100%;
  height: 58px;
  z-index: 500;

  @media (min-width: 480px) {
    background-color: ${props => props.theme.darkBlue};
    height: 66px;
  }
`;

const DrawerLogo = styled("img")`
  width: 220px;
  height: auto;
`;

const Logo = styled("img")`
  width: auto;
  height: 2.5rem;
  margin-top: 0.3em;
`;
