import parse, { domToReact } from "html-react-parser";
import { Link, NavLink } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
//TODO this might be the best way to handle rich text (ckeditor etc) out of Strapi
export default function parseWithLinks(text = "", parseOptions = {}) {
  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "img" && parseOptions?.imageClass) {
        const { src, alt, style } = attribs;

        return (
          <Box
            component={"img"}
            src={src}
            alt={alt}
            className={parseOptions?.imageClass}
            // sx={{ mb: "2rem", mt: "2rem" }}
          />
        );
      }
      // if (name === "figure" && attribs.class !== "image image_resized") {
      //   return <>{domToReact(children, { ...attribs, ...options })}</>;
      // }
      if (name === "a" && attribs.href) {
        // Trap for external links and set target to
        const regex = /https:\/\//;
        const TARGET = regex.test(attribs.href) ? "Clearwater" : null;
        if (parseOptions?.useButtonsForLinks === true) {
          return (
            <CustomButton
              component={NavLink}
              to={attribs.href}
              variant={parseOptions?.buttonVariant || "contained"}
              color={parseOptions.buttonColor}
              target={TARGET}
              hoverColor={parseOptions.hoverColor}
            >
              {domToReact(children, options)}
            </CustomButton>
          );
        }
        return (
          <Link to={attribs.href} target={TARGET}>
            {domToReact(children, options)}
          </Link>
        );
      }

      if (name === "li") {
        return (
          <li
            style={{ color: parseOptions?.color, textAlign: parseOptions?.alignText || "center" }}
          >
            {domToReact(children, options)}
          </li>
        );
      }
      if (name === "h1") {
        return (
          <Typography
            variant={name}
            color={parseOptions?.color}
            align={parseOptions?.alignText || "center"}
            paragraph
          >
            {domToReact(children, options)}
          </Typography>
        );
      }
      if (name === "h2") {
        return (
          <Typography
            variant={name}
            color={parseOptions?.color}
            align={parseOptions?.alignText || "center"}
            paragraph
          >
            {domToReact(children, options)}
          </Typography>
        );
      }
      if (name === "h3") {
        return (
          <Typography
            variant={name}
            color={parseOptions?.color}
            align={parseOptions?.alignText || "center"}
            paragraph
          >
            {domToReact(children, options)}
          </Typography>
        );
      }
      if (name === "h4") {
        return (
          <Typography
            variant={name}
            color={parseOptions?.color}
            align={parseOptions?.alignText || "center"}
            paragraph
          >
            {domToReact(children, options)}
          </Typography>
        );
      }
      if (name === "p") {
        return (
          <Typography
            variant={"body1"}
            align={parseOptions?.alignText || "center"}
            color={parseOptions?.color}
            paragraph
          >
            {domToReact(children, options)}
          </Typography>
        );
      }
    },
  };

  return parse(text, options);
}
const CustomButton = styled(Button)(props => ({
  "&:hover": {
    backgroundColor: props.hoverColor, // replace with your desired hover color
  },
}));
