import React from "react";
import styled from "@emotion/styled";

import { Box, Grid } from "@mui/material";
import { parseWithLinks } from "../Functions";
export default function Services(props) {
  return (
    <Wrapper component={Grid} container>
      {props?.Services?.map((service, index) => {
        return (
          <Box
            component={Grid}
            item
            container
            alignContent={{ xs: "", md: "space-between" }}
            xs={12}
            sm={6}
            md={6}
            p={{ xs: "5rem 1rem 3rem", md: "6rem 4rem 4rem" }}
            bgcolor={index === 0 ? "rgba(16,72,100,1)" : props?.theme?.palette?.primary?.red}
            minHeight={{ xs: "100dvw", sm: 416 }}
          >
            {parseWithLinks(service.ExpandedText, {
              buttonVariant: "outlined",
              alignText: "left",
              useButtonsForLinks: true,
              hoverColor: service?.options?.layout?.buttonHoverColor,
            })}
          </Box>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled(({ ...props }) => <Box {...props} />)`
  color: #fff;

  .MuiTypography-root {
    color: #fff;
  }

  .MuiTypography-body1 {
    width: 100%;
  }

  .MuiButton-root {
    margin-top: 2rem;
    max-width: calc(100dvw - 2rem);
    width: 100dvw;
    ${props => props.theme.breakpoints.up("sm")} {
      max-width: 285px;
    }
  }
`;
