import React from "react";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

import LoadingProgress from "../components/LoadingProgress";
import { ButtonStyles } from "../ButtonStyles";
import DynamicComponentLoader from "../components/DynamicComponent";

export default function Home({ handleBookATourClick, isLoading, ...props }) {
  const { PageContent: Content } = props?.data;
  return (
    <Wrapper id="home" disableGutters maxWidth={"xl"}>
      <Helmet>
        <title itemProp="name" lang="en">
          Clearwater Park
        </title>
      </Helmet>
      <Container disableGutters align="center" maxWidth={false}>
        <LoadingProgress isLoading={isLoading} />
        {Content?.map((block, index) => {
          return <DynamicComponentLoader key={`HomeDL${index}`} theme={props?.theme} {...block} />;
        })}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  ${ButtonStyles}

  ul {
    display: inline-block;
    width: 100%;
    max-width: 575px;
    text-align: left;
    list-style: ${({ options }) => {
      return options?.bullet !== undefined ? "none" : "disc";
    }};
    list-style-position: outside;
    // padding: 0;
    ${props => props.theme.breakpoints.only("xs")} {
      /* list-style: disc; */
      padding-left: 1rem;
    }
    font-size: 1em;
    ${props => props.theme.breakpoints.up("sm")} {
      /* list-style: disc; */
      padding-left: 1em;
    }
    &:last-child {
      margin-bottom: 0;
      ${props => props.theme.breakpoints.up("sm")} {
        /* list-style: disc; */
        /* margin-bottom: 2em; */
      }
    }
    li::marker {
      color: ${props => props.theme.palette.primary.main};
    }
    li {
      color: ${props => props.theme.palette.text.primary};
      margin: 0 1rem 0 0 !important;
      display: list-item;
      margin-bottom: 0.25rem;
      break-inside: avoid-column;
      line-height: 35px;

      strong {
        color: ${props => props.theme.palette.text.strong};
      }

      /* TODO Handle no bullet object better */
      &:before {
        /* content: url(${({ options }) => options?.bullet?.file}); */
        display: inline-block;
        position: absolute;
        /* margin-left: ${({ options }) => options?.bullet?.spacing}; */
        margin-top: 3.2px;
      }
      & > a {
        color: ${props => props.theme.palette.primary.main};

        &:hover {
          color: ${props => props.theme.palette.secondary.main};
        }
        &:visited {
          color: ${props => props.theme.palette.primary.dark};
        }
        &:active {
          color: ${props => props.theme.palette.primary.dark};
        }
      }
    }
  }
`;
