import React from "react";
import styled from "@emotion/styled";

import { Container, CircularProgress, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import { Outlet } from "react-router";

export default function News({ isLoading, ...props }) {
  // const { PageContent: Content } = props?.data;

  return (
    <Wrapper id={`${props.pageId}`}>
      <Helmet>
        <title itemProp="name" lang="en">
          News
        </title>
      </Helmet>
      <Container disableGutters align="center" maxWidth={false}>
        {isLoading && (
          <div>
            <CircularProgress sx={{ mt: "5rem" }} thickness={1} size={100} />
            <Typography>Loading Content... </Typography>
          </div>
        )}
        {/* NewsSection, NewsDetail are rendered in the Outlet */}
        <Outlet />
      </Container>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
